define(['siteObj', '$console'], function(siteObj, $console) {

  var reevooClient = function() {

    if (!siteObj.customerReviews.thirdParty && !siteObj.customerReviews.thirdParty.reevoo) {
      $console.warn('The Reevoo TKREF is not set in siteconfig');
      return;
    }

    this.config = siteObj.customerReviews.thirdParty.reevoo;
    this.tkref = this.config.tkref;
    this.productId = siteObj.productID;
    this.loadingMessage = this.config.loadingMessage;
    this.anchorClass = this.config.anchorClass;

    if (siteObj.features.enableReevooV2API) {
      this.js = `//widgets.reevoo.com/loader/${siteObj.customerReviews.thirdParty.reevoo.tkref}.js`;
    } else {
      this.js = '//cdn.mark.reevoo.com/assets/reevoo_mark.js';
      this.css = '//mark.reevoo.com/stylesheets/reevoomark/embedded_reviews.css';
    }

    this.badges = [];

    if (this.tkref) {
      this.load();
    }
  };

  reevooClient.prototype = {
    isProductPageBadge: function(badge) {
      return badge.product.sku === this.productId;
    },
    getElements: function() {
      var badgeEl;
      if (siteObj.features.enableReevooV2API) {
        badgeEl = document.getElementsByTagName('reevoo-badge');
      } else {
        badgeEl = document.getElementsByTagName('reevoo-reviewable-badge');
      }

      this.$elements = {
        $head: document.getElementsByTagName('head')[0],
        $marks: badgeEl,
      };
    },
    load: function() {
      var init = function() {
        var js;

        this.getElements();

        if (!this.$elements.$marks.length) {
          return;
        }

        js = document.createElement('script');
        js.async = true;
        js.id = 'reevoo-loader'
        js.src = this.js;

        this.$elements.$head.appendChild(js);

        if (!siteObj.features.enableReevooV2API) {
          var css = document.createElement('link');
          css.type = 'text/css';
          css.rel = 'stylesheet';
          css.href = this.css;

          this.$elements.$head.appendChild(css);
          this.print();
        }

      }.bind(this);

      init();
    },
    print: function() {
      var onMarkLoaded;
      var onApiLoaded;

      var printLink = function(badge) {
        var readLink;
        var reviewsText;
        var scoreHtml;
        var linkHtml;

        scoreHtml = '<span class="reevoo-score">' + badge.product.average_score + '/10</span>';
        reviewsText = badge.product.review_count > 1 ? 'reviews' : 'review';
        linkHtml = '<a class="' + this.anchorClass + '" href="#' + this.config.tab.hashReference + '">Read ' + badge.product.review_count + ' ' + reviewsText + '</a>';

        readLink = document.createElement('div');
        readLink.className = 'reevoo-link-container';

        readLink.innerHTML = this.isProductPageBadge(badge) ? scoreHtml + linkHtml : scoreHtml;

        badge.target.parentElement.appendChild(readLink);
      }.bind(this);

      var addBadge = function(badge) {
        this.badges.push(badge);
        if (badge.product.review_count) {
          printLink(badge);
        }
      }.bind(this);

      onApiLoaded = function(retailer) {
        retailer.init_badges();
        retailer.init_reevoo_reputation_badges();
        retailer.observe('load', addBadge);
      }.bind(this);

      onMarkLoaded = function() {
        window.ReevooApi.load(this.tkref, onApiLoaded);
      }.bind(this);

      /* Reevoo needs 'afterReevooMarkLoaded' on global/window - letting it Hoist */
      if (typeof afterReevooMarkLoaded === 'undefined') {
        window.afterReevooMarkLoaded = [];
      }
      window.afterReevooMarkLoaded.push(onMarkLoaded);
    }
  };

  new reevooClient();
});
